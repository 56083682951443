@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.modal_open_Effect {
  /* scale: 0; */
  animation: scaleIn 0.3s forwards;
}

.closed {
  /* scale: 1; */
  animation: scaleOut 0.3s forwards;
}
